<template>
  <el-breadcrumb class="tw_breadcrumbs" separator-class="chevron-right">
    <el-breadcrumb-item v-for="(page, index) in pages" :key="index" :to="page.name ? {name: page.name} : page.to ? {path: page.to} : null">{{page.label}}</el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script>
export default {
  name: 'TwBreadcrumbs',
  props: {
    pages: Array
  },
};
</script>

<style lang="scss">
  .tw_breadcrumbs {
    font-size: 12px;
    line-height: 18px;
    margin :10px 0 20px;
    
    .el-breadcrumb__separator.chevron-right {
      display: inline-block;
      width: 14px;
      height: 14px;
      line-height: 1;
      vertical-align: middle;
      margin: 0 8px;
      background: url(../../assets/images/icons/chevron_right_darkblue.svg) no-repeat center;
      background-size: 14px auto;
      margin-bottom: 1px;
    }

    .el-breadcrumb__item:last-child .el-breadcrumb__separator {
      display: none;
    }

    .el-breadcrumb__item:last-child .el-breadcrumb__inner {
      color: $color_black;
    }

    .el-breadcrumb__inner, .el-breadcrumb__inner.is_link {
      color: $color_gray_600;
    }

    .el-breadcrumb__inner.is-link, .el-breadcrumb__inner a {
      position: relative;
      display: inline;
      text-decoration: none;
      font-weight: normal;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      // &:after {
      //   position: absolute;
      //   top: 75%;
      //   left: 0;
      //   content: '';
      //   width: 100%;
      //   height: 1px;
      //   background: #5185C5;
      //   transform: scale(0, 1);
      //   transform-origin: left top;
      //   transition: transform .3s;
      // }

      // &:hover:after {
      //   color: $color_dark_blue;
      //   transform: scale(1, 1);
      // }
    }
  }
</style>
