<template>
  <div class="tw_entity_icon" :class="classes" @click="onClick">
    <el-tooltip v-if="popover" placement="top" popper-class="mcu nowrap" :tabindex="-1">
      <div slot="content">{{nameEn}}</div>
      <i/>
    </el-tooltip>
    <i v-else />
  </div>
</template>

<script>
import { entityNames } from '@/dictionaries/map.js';

export default {
  name: 'TwEntityIcon',
  props: {
    entityName: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'medium',
    },
    color: {
      type: String,
      default: 'color',
    },
    popover: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        [`tw_entity_icon__${entityNames[this.entityName].iconName}`]: true,
        [`tw_entity_icon__${this.size || 'medium'}`]: true,
        [`tw_entity_icon__${this.color || 'color'}`]: true,
      };
    },
    nameEn() {
      return this.$t(`Entity.${entityNames[this.entityName].nameEn}`);
    }
  },
  methods: {
    onClick() {
      this.$emit('click', entityNames[this.entityName]);
    }
  }
};
</script>

<style lang="scss" scoped>
  .tw_entity_icon {
    display: inline-block;
    font-size: 0;
    flex-shrink: 0;

    i {
      display: inline-block;
      width: 100%;
      height: 100%;
    }

    i {
      background: no-repeat center;
      background-size: cover;
    }

    &.tw_entity_icon__color {
      &.tw_entity_icon__ex_large {
        width: 40px;
        height: 40px;
      }

      &.tw_entity_icon__large {
        width: 26px;
        height: 26px;
      }

      &.tw_entity_icon__24 {
        width: 24px;
        height: 24px;
      }

      &.tw_entity_icon__medium {
        width: 20px;
        height: 20px;
      }

      &.tw_entity_icon__small{
        width: 16px;
        height: 16px;
      }

      &.tw_entity_icon__ex_small{
        width: 12px;
        height: 12px;
      }

      &.tw_entity_icon__contract i {
        background-image: url(../../assets/images/icons/sales_contract.svg);
      }

      &.tw_entity_icon__lc i {
        background-image: url(../../assets/images/icons/lc.svg);
      }

      &.tw_entity_icon__transportation_agreement i {
        background-image: url(../../assets/images/icons/transportation_agreement.svg);
      }

      &.tw_entity_icon__ex_customs i {
        background-image: url(../../assets/images/icons/ex_custom.svg);
      }

      &.tw_entity_icon__marine_ip i {
        background-image: url(../../assets/images/icons/insurance.svg);
      }

      &.tw_entity_icon__transportation i {
        background-image: url(../../assets/images/icons/transport.svg);
      }

      &.tw_entity_icon__final_docs i {
        background-image: url(../../assets/images/icons/final_docs.svg);
      }

      &.tw_entity_icon__im_customs i {
        background-image: url(../../assets/images/icons/im_custom.svg);
      }
    }

    &.tw_entity_icon__color_shadow {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      cursor: pointer;

      i {
        transition: opacity .2s;
      }

      &:hover i {
        opacity: .5;
      }

      &.disabled {
        box-shadow: none!important;
        pointer-events: none;

        i {
          background: none!important;
        }
      }

      &.tw_entity_icon__ex_large {
        width: 60px;
        height: 60px;

        i {
          width: 60px * 0.7;
          height: 60px * 0.7;
        }
      }

      &.tw_entity_icon__large {
        width: 40px;
        height: 40px;

        i {
          width: 40px * 0.7;
          height: 40px * 0.7;
        }
      }

      &.tw_entity_icon__medium {
        width: 32px;
        height: 32px;

        i {
          width: 32px * 0.7;
          height: 32px * 0.7;
        }
      }

      &.tw_entity_icon__small {
        width: 26px;
        height: 26px;

        i {
          width: 26px * 0.7;
          height: 26px * 0.7;
        }
      }

      &.tw_entity_icon__xs_small {
        width: 20px;
        height: 20px;

        i {
          width: 20px * 0.7;
          height: 20px * 0.7;
        }
      }

      &.tw_entity_icon__contract {
        background: $color_white;
        @include drop_shadow;
        border-radius: 6px;

        i {
          background-image: url(../../assets/images/icons/sales_contract.svg);
        }

        &.disabled {
          background-image: url(../../assets/images/icons/sales_contract_gray.svg);
        }
      }

      &.tw_entity_icon__lc {
        background: $color_white;
        @include drop_shadow;
        border-radius: 6px;

        i {
          background-image: url(../../assets/images/icons/lc.svg);
        }

        &.disabled {
          background-image: url(../../assets/images/icons/lc_gray.svg);
        }
      }

      &.tw_entity_icon__transportation_agreement {
        background: $color_white;
        @include drop_shadow;
        border-radius: 6px;

        i {
          background-image: url(../../assets/images/icons/transportation_agreement.svg);
        }

        &.disabled {
          background-image: url(../../assets/images/icons/transportation_agreement_gray.svg);
        }
      }

      &.tw_entity_icon__ex_customs {
        background: $color_white;
        @include drop_shadow;
        border-radius: 6px;

        i {
          background-image: url(../../assets/images/icons/ex_custom.svg);
        }

        &.disabled {
          background-image: url(../../assets/images/icons/ex_custom_gray.svg);
        }
      }

      &.tw_entity_icon__marine_ip {
        background: $color_white;
        @include drop_shadow;
        border-radius: 6px;

        i {
          background-image: url(../../assets/images/icons/insurance.svg);
        }

        &.disabled {
          background-image: url(../../assets/images/icons/insurance_gray.svg);
        }
      }

      &.tw_entity_icon__transportation {
        background: $color_white;
        @include drop_shadow;
        border-radius: 6px;

        i {
          background-image: url(../../assets/images/icons/transport.svg);
        }

        &.disabled {
          background-image: url(../../assets/images/icons/transport_gray.svg);
        }
      }

      &.tw_entity_icon__final_docs {
        background: $color_white;
        @include drop_shadow;
        border-radius: 6px;

        i {
          background-image: url(../../assets/images/icons/final_docs.svg);
        }

        &.disabled {
          background-image: url(../../assets/images/icons/final_docs_gray.svg);
        }
      }

      &.tw_entity_icon__im_customs {
        background: $color_white;
        @include drop_shadow;
        border-radius: 6px;

        i {
          background-image: url(../../assets/images/icons/im_custom.svg);
        }

        &.disabled {
          background-image: url(../../assets/images/icons/im_custom_gray.svg);
        }
      }
    }

    &.tw_entity_icon__white {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      &.tw_entity_icon__ex_large {
        width: 60px;
        height: 60px;

        i {
          width: 60px * 0.7;
          height: 60px * 0.7;
        }
      }

      &.tw_entity_icon__large {
        width: 40px;
        height: 40px;

        i {
          width: 40px * 0.7;
          height: 40px * 0.7;
        }
      }

      &.tw_entity_icon__medium {
        width: 32px;
        height: 32px;

        i {
          width: 32px * 0.7;
          height: 32px * 0.7;
        }
      }

      &.tw_entity_icon__small {
        width: 26px;
        height: 26px;

        i {
          width: 26px * 0.7;
          height: 26px * 0.7;
        }
      }

      &.tw_entity_icon__24 {
        width: 24px;
        height: 24px;

        i {
          width: 24px * 0.7;
          height: 24px * 0.7;
        }
      }

      &.tw_entity_icon__xs_small {
        width: 20px;
        height: 20px;

        i {
          width: 20px * 0.7;
          height: 20px * 0.7;
        }
      }

      &.tw_entity_icon__contract {
        @include gradation_sales_contract;

        i {
          background-image: url(../../assets/images/icons/sales_contract_white.svg);
        }
      }

      &.tw_entity_icon__lc {
        @include gradation_lc;

        i {
          background-image: url(../../assets/images/icons/lc_white.svg);
        }
      }

      &.tw_entity_icon__transportation_agreement {
        @include gradation_transportation_agreement;

        i {
          background-image: url(../../assets/images/icons/transportation_agreement_white.svg);
        }
      }

      &.tw_entity_icon__ex_customs {
        @include gradation_ex_customs_clearance;

        i {
          background-image: url(../../assets/images/icons/ex_custom_white.svg);
        }
      }

      &.tw_entity_icon__marine_ip {
        @include gradation_insurance;

        i {
          background-image: url(../../assets/images/icons/insurance_white.svg);
        }
      }

      &.tw_entity_icon__transportation {
        @include gradation_transport;

        i {
          background-image: url(../../assets/images/icons/transport_white.svg);
        }
      }

      &.tw_entity_icon__final_docs {
        @include gradation_payment;

        i {
          background-image: url(../../assets/images/icons/final_docs_white.svg);
        }
      }

      &.tw_entity_icon__im_customs {
        @include gradation_import_customs;

        i {
          background-image: url(../../assets/images/icons/im_custom_white.svg);
        }
      }
    }
  }
</style>
