<template>
  <div class="tw_notice_list" v-infinite-scroll="load" infinite-scroll-disabled="disabled">
    <div class="notice_head">
      <h3>{{$t('Label.Notice')}}</h3>
      <div class="column">
        <el-checkbox v-model="unreadOnly" @change="onChange">{{$t('Label.Unread Only')}}</el-checkbox>
        <el-dropdown-item><img class="icon_close" src="@/assets/images/icons/times_gray600.svg"></el-dropdown-item>
      </div>
    </div>
    <div class='notice_list_item' v-for='(item, index) in items' :key='index' :class='{unread: item.read === 0}'
         @click='onNoticeClick(item)'>

      <div class='notice_item_inner'>
        <div class='process_icon'>
          <template v-if='item.processId'>
            <tw-entity-icon :entityName='getEntityNameByProcessId(item.processId)' size='large' />
          </template>

          <span v-else class='default_icon'>
            <img src='../../assets/images/icons/icon_bell.svg' alt=''>
          </span>
        </div>

        <div class='notice_item'>
          <div class='from_name'>{{ item.userNameFrom }}</div>

          <div class='comment' v-if='item.browserNoticeMessage'
               v-html='sanitize(item.browserNoticeMessage, item.processName)' />

          <div class='date'>{{ item.noticeDate | dateTimeFormat }}</div>
        </div>
      </div>
    </div>
    <div v-if="!items.length" class="empty_message">{{$t('Message.NoResult1')}}</div>
    <div class="loading_box">
      <img v-if="loading" class="loading" src="@/assets/images/loading.gif">
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { UNREAD_FLG } from 'lib-tw-common';
import {getEntityNameByProcessId} from '@/utils/entity'
import TwEntityIcon from '../atoms/TwEntityIcon.vue';

export default {
  name: 'App',
  components: {
    TwEntityIcon

  },
  data() {
    return {
      unreadOnly: false,
      loading: false,
      referenceDate: '',
      limit: 20,
      getEntityNameByProcessId,
    };
  },
  computed: {
    items() {
      return this.$store.state.notifications;
    },
    sanitize() {
      return (string, processName) => {
        return this.$sanitize(string).replace(/\n/g, '<br>').replace(processName, `<span class="process_name">${processName}</span>`);
      }
    },
    noMore() {
      return this.limit > this.items.length;
    },
    disabled() {
      return this.loading || this.noMore;
    }
  },
  created() {
    this.unreadOnly = this.$store.getters.getUnreadOnlyNotice;
  },
  methods: {
    fetch(referenceDate) {
      this.$store.dispatch('GET_NOTIFICATION_LIST', {unReadFlg: this.unreadOnly ? UNREAD_FLG.UNREAD : UNREAD_FLG.ALL, sendNotification: false, referenceDate});
    },
    onNoticeClick(item) {
      this.$router.push({
        name: `Process${_.capitalize(item.processId.replace(/\d/g, ''))}`,
        params: { entityId: item.entityId, processSeq: item.processSeq, _processId: item.processId.toLowerCase() },
      })
      .catch(error => {
        if (error.name !== 'NavigationDuplicated') throw error;
      });
      if (!item.read) {
        this.$store.dispatch('READ_NOTIFICATION', item.browserNoticeId);
        this.$store.commit('SET_UNREAD_COUNT', Math.max(this.$store.getters.getUnreadCount - 1, 0));
      }
    },
    onChange() {
      this.$store.commit('SET_UNREAD_ONLY_NOTICE', this.unreadOnly);
      this.limit = 20;
      this.fetch();
    },
    async load() {
      const noticeDate = this.items[this.items.length - 1].noticeDate;
      if (this.referenceDate === noticeDate) return
      this.loading = true;
      await this.fetch(noticeDate);
      setTimeout(() => {
        this.referenceDate = this.items[this.items.length - 1].noticeDate;
        this.limit += 20;
        this.loading = false;
      }, 200);
    }
  }
};
</script>

<style lang="scss">
  .tw_notice_list {
    width: 500px;
    max-height: 572px;
    overflow-y: auto;
    padding: 0;

    .notice_head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 17px 0 20px;

      h3 {
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
      }

      .column {
        display: flex;
        align-items: center;
      }

      .el-checkbox__label {
        font-size: 14px;
        line-height: 20px;
        color: #8F90A6;
      }

      .el-dropdown-menu__item {
        width: 24px;
        height: 24px;
        margin-left: 29px;
        padding: 0;

        &:hover {
          background: none;
          opacity: .5;
        }
      }

      img.icon_close {
        display: block;
        width: 24px;
        height: 24px;
      }
    }

    .notice_list_item {
      border-bottom: 1px solid $color_gray_300;
      padding: 12px 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 20px;
      color: $color_gray_800;
      font-size: 12px;
      transition: all 0.3s;

      &:hover {
        background: rgba($color_dark_blue, 0.05);
      }

      &.unread {
        background: rgba($color_dark_blue, 0.15);

        .from_name {
          font-weight: 600;
        }

        .comment {
          font-weight: 600;
        }

        &:hover {
          background: rgba($color_dark_blue, 0.2);
        }
      }
    }

    .comment span.process_name {
      color: $color_primary_blue;
    }
    .notice_item_inner {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: space-between;

      .column {
        padding-right: 8px;
      }

      .notice_item {
        flex: 1;
      }

      .process_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        @include card_shadow;

        .default_icon {
          opacity: 0.5;
        }
      }

      .delete {
        display: inline-flex;
        align-items: center;
        font-weight: bold;
        flex-shrink: 0;
        height: 16px;
        font-size: 10px;
        line-height: 14px;
        border-radius: 8px;
        padding: 0 8px;
        margin-left: 8px;
        background: #E00001;
        color: $color_white;
      }
    }

    .from_name {
      font-size: 14px;
      line-height: 1.7;
    }

    .comment {
      font-size: 14px;
      line-height: 1.6;

      p {
        margin: 0;
      }
    }

    .date {
      font-size: 12px;
      line-height: 20px;
      margin-top: 4px;
      color: $color_gray_600;
    }

    .empty_message {
      font-weight: 500;
      font-size: 20px;
      line-height: 27px;
      color: $color_black;
      margin-top: 33px;
      margin-bottom: 20px;
      padding-right: 20px;
      padding-left: 20px;
    }

    .loading_box {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      img.loading {
        display: block;
        width: 24px;
        height: 24px;
        margin: 12px auto;
      }
    }
  }
</style>
