import _ from 'lodash';
import { TRADINGFLOWLIST_PROCESS_ORDER_VARIABLES } from 'lib-tw-common';
import { processNames } from '../dictionaries/map';

/**
 * processIdによってEntityの名前を返却する
 * @param {string | undefined | null} processId
 * @returns {string}
 */

// eslint-disable-next-line import/prefer-default-export
export const getEntityNameByProcessId = (processId) => {
  if (processId) {
    for (const key in processNames) {
      if (processNames[key].processIds.includes(processId)) {
        return processNames[key].parent;
      }
    }
  }

  return '';
};

/**
 * TRADINGFLOWLIST_PROCESS_ORDER_VARIABLESに従ってプロセスリストの並び替えをおこなったフロー（エンティティリスト）を返却する
 * @param {Array} processListを有するEntityList
 * @param {string} processListKey
 * @returns {Array} processListを有するEntityList
 */
export function orderProcesses(entityList, processListKey = 'processStatus') {
  let result = _.cloneDeep(entityList)
  for(const entity of result) {
    entity[processListKey] = entity[processListKey].sort((a, b) => {
      const idA = TRADINGFLOWLIST_PROCESS_ORDER_VARIABLES.find((item) => item.id === a.processId)?.code || Number.MAX_VALUE;
      const idB = TRADINGFLOWLIST_PROCESS_ORDER_VARIABLES.find((item) => item.id === b.processId)?.code || Number.MAX_VALUE;
      return idA > idB ? 1 : -1;
    });
  }
  return result;
}

/**
 * processIdからプロセスの番号を返す
 * @param {String} processListを有するEntityList
 * @returns {String}
 */
export function getProcessNumber(processId) {
  return /^(ECREQ|ICREQ|TPBLI|FDCOR|FDCOI|MIRIP)0/.test(processId) ? ` ${_.last(processId)}` : '';
}
